import request from '@/utils/request'

export function getserverlist() {
    return request({
      hideLoading: true,
      url: '/admin/getserverlist',
      method: 'get'
    })
}

export function GetTerminalInfoByJiance(data) {
  return request({
    hideLoading: true,
    url: '/admin/GetTerminalInfoByJiance?startdata='+data.startdata+'&enddata='+data.enddata+'&startnumbers='+data.startnumbers+'&endnumbers='+data.startnumbers,
    method: 'get',
  })
}

export function GetTerminalInfoByTestAndTime(data) {
  return request({
    hideLoading: true,
    url: '/admin/GetTerminalInfoByTestAndTime?startdata='+data.startdata+'&startnumbers='+data.startnumbers+'&endnumbers='+data.endnumbers,
    method: 'get',
  })
  
}

export function sendmessage(data) {
  return request({
    hideLoading: true,
    url: '/admin/sendmessage?channelid='+data.channelid+'&message='+data.message,
    method: 'get',
  })
  
}