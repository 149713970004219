<template>
    <div class="container" style="width:95%; margin:30px auto;">
      <div class="title" style="font-size:50px; font-weight:600; text-align:center; margin-bottom:30px;">
        采集器生产测试
      </div>
      <div class="test-search" style="text-align:center; margin-bottom:30px;">
        <div style="float: left; margin-right:30px;">
          <input v-model="searchQuery" placeholder="输入采集器标识" style="width: 260px; height: 35px; box-sizing: border-box; margin-right: 15px; font-size: 20px; padding: 0 10px;" />
          <button @click="search" style="width: 80px; height: 35px; box-sizing: border-box; cursor: pointer;">搜索</button>
        </div>
        <div style="float:right;">
          <input v-model.number="startNumber" placeholder="开始终端号" style="width:180px; height:35px; box-sizing: border-box; margin-right:5px; font-size:20px; padding:0 10px;" />
          <span style="margin-right:5px; font-size:25px;">-</span>
          <input v-model.number="endNumber" placeholder="结束终端号" style="width:180px; height:35px; box-sizing: border-box; margin-right:15px; font-size:20px; padding:0 10px;" />
          <button @click="clearData" style="width: 80px; height: 35px; box-sizing: border-box; cursor: pointer;">清除</button>&nbsp;
          <button :class="{ statusstyle: isTesting }" @click="startTesting" style="width: 80px; height: 35px; box-sizing: border-box; cursor: pointer;">
            {{ isTesting ? '停止检测' : '开始检测' }}
          </button>
        </div>
        <div style="clear:both;"></div>
      </div>
      <div class="tablelist">
        <div class="list_th" style="font-size: 30px; text-align: center; background-color: #f4f4f4; padding: 10px 0; border-radius: 3px; overflow: hidden;">
          <div class="list_td_terminalid">模块编号</div>
          <div class="list_td_other">开机</div>
          <div class="list_td_other">磁铁</div>
          <div class="list_td_other">测试1</div>
          <div class="list_td_other">测试2</div>
          <div class="list_td_other">自检</div>
          <div class="list_td_other">局放</div>
          <div class="list_td_other">电压</div>
          <div style="clear: both;"></div>
        </div>
        <div id="Terminalsdiv" class="terminals-div" style="height:500px; overflow-y:auto;" >
          <div
            v-for="(terminal, index) in Terminals"
            :key="terminal.TerimalNumber"
            class="list_tr"
            style="border-bottom:1px solid #fff;"
          >
            <div class="terminalid blue">{{ terminal.dataTerminal }}</div>
            <div :class="['start', getStartClass(terminal)]">{{ terminal.temperature }}</div>
            <div :class="['citie', getMagnetClass(terminal)]">&nbsp;</div>
            <div :class="['test1', getTest1Class(terminal)]">{{ terminal.test1Temperature }}</div>
            <div :class="['test2', getTest2Class(terminal)]">{{ terminal.test2Temperature }}</div>
            <div :class="['zijian', getZijianClass(terminal)]">&nbsp;</div>
            <div :class="['jufang', getJufangClass(terminal)]">&nbsp;</div>
            <div :class="['voltage orange']">{{ terminal.Voltage }}</div>
            <div style="clear: both;"></div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { GetTerminalInfoByTestAndTime } from '@/api/admin';
  export default {
    data() {
      return {
        searchQuery: '',
        startNumber: '0000000001',
        endNumber: '1000000000',
        isTesting: false,
        Terminals: [],
        currentTime: null,
        timer: null,
        getTerminalnum: 0,
      };
    },
    methods: {
      getStartClass(terminal) {
        return terminal.status === 16 ? 'green' : '';
      },
      getMagnetClass(terminal) {
        return terminal.status1 === 2 ? 'gray' : '';
      },
      getTest1Class(terminal) {
        return terminal.status2 === 4 ? 'yellow' : '';
      },
      getTest2Class(terminal) {
        return terminal.status3 === 8 ? 'red' : '';
      },
      getZijianClass(terminal) {
        return terminal.status4 === 32 ? 'blue' : '';
      },
      getJufangClass(terminal) {
        return terminal.status5 === 64 ? 'green' : '';
      },
      search() {
        // 实现搜索逻辑
        console.log('搜索:', this.searchQuery);
      },
      clearData() {
        this.Terminals = [];
        this.currentTime = new Date();
        this.getTerminalnum = 0;
        this.stopTesting();
      },
      startTesting() {
        if (!this.isTesting) {
          this.isTesting = true;
          this.currentTime = '2024-12-01 08:23:59';
          //this.currentTime = this.formatDate(new Date(),'YYYY-MM-DD HH:mm:ss');
          //this.currentTime = new Date();
          this.timer = setInterval(this.getTerminals, 2000);
          //this.getTerminals();
        } else {
          this.stopTesting();
        }
      },
      stopTesting() {
        this.isTesting = false;
        clearInterval(this.timer);
      },
      formatDate(date, format) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return format
            .replace('YYYY', year)
            .replace('MM', month)
            .replace('DD', day)
            .replace('HH', hours)
            .replace('mm', minutes)
            .replace('ss', seconds);
      },

      getTerminals() {
        // const startnum = this.startNumber;
        // const endnum = this.endNumber;
        //console.log('currentTime=', this.currentTime, 'getTerminalnum=', this.getTerminalnum, 'startnum=', startnum);
        // 模拟AJAX请求
        GetTerminalInfoByTestAndTime({
            startdata: this.currentTime == null ? '' : this.currentTime,
            startnumbers: this.startNumber == null ? '' : this.startNumber,
            endnumbers: this.endNumber  == null ? '' : this.endNumber,
          })
        .then((res) => {
            
            //alert(JSON.stringify(res));
            const data = res.data;
            console.log('res=', data);
            var str = "";
            //$("#Terminalsdiv").html($("#Terminalsdiv").html() + '<div class="list_tr" style="border-bottom:1px solid #fff"><div class="normal">00000'+getTerminalnum+'</div><div ></div><div ></div><div style="clear: both"></div></div>')
            for (var i = 0; i < data.length; i++) {
                if (data[i].dataStatus == "2" || data[i].dataStatus == "4" || data[i].dataStatus == "8" || data[i].dataStatus == "16" || data[i].dataStatus == "32" || data[i].dataStatus == "64")
                {
                    var t = true; //还没有记录
                    //console.error("获取到长度" + this.Terminals.length)
                    for (var j = 0; j < this.Terminals.length; j++) {
                      //console.error(this.Terminals[j])
                        if (this.Terminals[j].dataTerminal == data[i].dataTerminal) {
                            if (this.Terminals[j].status1 == "") {//磁铁（第四个）标识 2
                                if (data[i].dataStatus == "2") {                                        
                                  this.Terminals[j].status1 = 2//number为2
                                  //   console.log("磁铁索引", $(".list_tr").eq(j).$(".citie").index())
                                  //$(".list_tr").eq(j).find("div").eq(2).addClass("gray");  //磁铁
                                }
                            }
                            if (this.Terminals[j].status2 == "") {
                                if (data[i].dataStatus == "4") {
                                    console.log("测试1索引" +j, data[i].dataTemperature)
                                    //把轻超超这一列置颜色
                                    this.Terminals[j].status2 = 4;
                                    this.Terminals[j].temperature1 = data[i].dataTemperature;
                                    // console.log("测试1索引", $(".list_tr").eq(j).$(".test1").index())
                                    // $(".list_tr").eq(j).find("div").eq(3).addClass("yellow"); //测试1
                                    // $(".list_tr").eq(j).find("div").eq(3).html(data[i].dataTemperature);
                                }
                                                                
                            }

                            if (this.Terminals[j].status3 == "") {
                                if (data[i].dataStatus == "8") {                                      
                                        //把轻超超这一列置颜色
                                        this.Terminals[j].status3 = 8;
      
                                        this.Terminals[j].temperature2 = data[i].dataTemperature;
                                        // if (this.Terminals[j].status2 == "")
                                        //     $(".list_tr").eq(j).find("div").eq(3).addClass("white");//测试1                                      
                                        // $(".list_tr").eq(j).find("div").eq(4).addClass("red");//测试2
                                        // $(".list_tr").eq(j).find("div").eq(4).html(data[i].dataTemperature);
                                        // console.log("测试1索引", $(".list_tr").eq(j).$(".test1").index())
                                        // console.log("测试2索引", $(".test2").index())                                       
                                }

                            }
                            if (this.Terminals[j].status4 == "") {
                                if (data[i].dataStatus == "32") {
                                    //把轻超超这一列置颜色                                      
                                    this.Terminals[j].status4 = 32;
                                      //  console.log("自检索引", $(".list_tr").eq(j).$(".zijian").index())
                                    // $(".list_tr").eq(j).find("div").eq(5).addClass("blue");//自检                                        
                                }
                            }
                            if (this.Terminals[j].status5 == "") {
                                if (data[i].dataStatus == "64") {
                                    //把轻超超这一列置颜色                                       
                                    this.Terminals[j].status5 = 64;
                                    //  console.log("局放索引", $(".list_tr").eq(j).$(".jufang").index())
                                    //$(".list_tr").eq(j).find("div").eq(6).addClass("green");//局放                                        
                                }

                            }
                            
                            t = false; //表示已经显示在上面
                        }
                    }
                    console.log("dddd",t);
                    if (t)
                    {   //status:记录开机状态16  ,temperature:开机温度，status1：2 磁铁，status2 4 轻超 ，  temperature2:轻超温度  ，status3 8 重超， temperature3 重超温度  ,status4 :自检 ，status5 :局放                          
                        console.log("11111");
                        this.Terminals.push({ dataTerminal: data[i].dataTerminal, status: 16, temperature: data[i].dataTemperature, status1: "", status2: "", temperature2: 0, status3: "", temperature3: 0,status4:"",status5:"" });
                        //this.Terminals[this.Terminals.length] = { dataTerminal: data[i].dataTerminal, status: 16, temperature: data[i].dataTemperature, status1: "", status2: "", temperature2: 0, status3: "", temperature3: 0,status4:"",status5:"" }//number为
                        //$("#Terminalsdiv").html($("#Terminalsdiv").html() + '<div class="list_tr" style="border-bottom:1px solid #fff"><div class="terminalid blue">' + data[i].dataTerminal + '</div><div class="start green">' + data[i].dataTemperature + '</div><div class="citie">&nbsp;</div><div  class="test1"></div><div class="test2">&nbsp;</div><div  class="zijian">&nbsp;</div><div  class="jufang">&nbsp;</div><div class="voltage orange">' + data[i].dataVoltage + '</div><div style="clear: both"></div></div>')
                        //console.log(this.Terminals[this.Terminals.length]);
                    }
                    console.log("eeee", t);
                }
          
            }
            // var h = $("#Terminalsdiv").height() - 500; //获取当前滚动条距离顶部的位置  
            // console.log(h);
            // $("#Terminalsdiv").scrollTop(50 * getTerminalnum);
  
            this.getTerminalnum++;
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .terminalid {
    width: 20%;
    height: 50px;
    line-height: 50px;
    font-size: 35px;
    text-align: center;
    color: #fff;
  }
  .start {
    width: 11.4%;
    height: 50px;
    line-height: 50px;
    font-size: 35px;
    text-align: center;
    color: #fff;
  }
  .voltage {
    width: 11.4%;
    height: 50px;
    line-height: 50px;
    font-size: 35px;
    text-align: center;
    color: #fff;
    background-color: orange;
  }
  .citie {
    width: 11.4%;
    height: 50px;
    line-height: 50px;
    font-size: 35px;
    text-align: center;
    color: #fff;
  }
  .test1 {
    width: 11.4%;
    height: 50px;
    line-height: 50px;
    font-size: 35px;
    text-align: center;
    color: #fff;
  }
  .test2 {
    width: 11.4%;
    height: 50px;
    line-height: 50px;
    font-size: 35px;
    text-align: center;
    color: #fff;
  }
  .zijian {
    width: 11.4%;
    height: 50px;
    line-height: 50px;
    font-size: 35px;
    text-align: center;
    color: #fff;
  }
  .jufang {
    width: 11.4%;
    height: 50px;
    line-height: 50px;
    font-size: 35px;
    text-align: center;
    color: #fff;
  }
  .gray {
    background-color: gray;
  }
  .green {
    background-color: #2e8b29;
  }
  .yellow {
    background-color: #ffc000;
  }
  .red {
    background-color: #ff0000;
  }
  .blue {
    background-color: #0000ff;
  }
  .orange {
    background-color: orange;
  }
  .white {
    background-color: #ffffff;
  }
  .statusstyle {
    background-color: #990000;
    color: #fff;
    border: none;
  }
  .list_tr {
    display: flex;
    flex-direction: row;
  }
  .list_th {
    display: flex;
    flex-direction: row;
  }
  .list_td_terminalid {
    width: 20%;
  }
  .list_td_other {
    width: 11.4%;
  }
  .container {
    width: 95%;
    margin: 30px auto;
  }
  .title {
    font-size: 50px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
  }
  .test-search {
    text-align: center;
    margin-bottom: 30px;
  }
  .terminals-div {
    height: 500px;
    overflow-y: auto;
  }
  </style>
  