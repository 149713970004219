import { login} from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import md5 from 'js-md5'
const getDefaultState = () => {
  return {
    token: getToken(),
  }
}

const state = getDefaultState()

const mutations = {

  SET_TOKEN: (state, token) => {
    state.token = token
  },
  
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ loginName: username.trim(), password: md5(password) }).then(response => {

        //alert(JSON.stringify(response));
        if (response.success === true) {
          const { data } = response
          commit('SET_TOKEN', data)
          setToken(data)
        }
        resolve(response)
      }).catch(error => {
        console.log('error', error)
        reject(error)
      })
    })
  },


  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

