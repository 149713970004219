<template>
    <div class="area2">
        <!-- <el-row>
            <el-button size="mini" type="primary"  @click="openM(1)">启动TCPServe</el-button>
            <el-button size="mini" type="danger">停止TCPServe</el-button>
        </el-row> -->
        <el-row class="serve-status">
            服务器状态：<span>运行中</span>
        </el-row>
        <el-row class="serve-list">
            <template>
                <el-table
                :data="serverData.hostlist"
                size="small "
                style="width: 50%;min-width: 800px;">
                <el-table-column label="主机信息" align="center">
                    <el-table-column
                        prop="hostNumber"
                        label="主机号"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="hostIp"
                        label="IP地址"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="hostPort"
                        width="100"
                        label="端口">
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="info"
                                plain
                                @click="handleDl(scope.$index, scope.row)">查询主机电量</el-button>
                            <el-button
                                size="mini"
                                type="info"
                                plain
                                @click="handleXh(scope.$index, scope.row)">查询主机信号</el-button>
                            <el-button
                                size="mini"
                                type="info"
                                plain
                                @click="handleKh(scope.$index, scope.row)">查询卡号</el-button>
                        </template>
                    </el-table-column>
                </el-table-column>
                </el-table>
            </template>
        </el-row>

        <el-row class="serve-list" :gutter="20">
            <el-col :span="8"> 
                <el-table
                size="small "
                :data="serverData.connectionlist">
                <el-table-column label="主机连接记录" align="center">
                        <el-table-column
                            prop="hostNumber"
                            label="主机编号"
                            width="150">
                        </el-table-column>
                        <el-table-column
                            prop="hostCode"
                            label="接收数据">
                        </el-table-column>
                        <el-table-column
                            prop="createdTime"
                            width="150"
                            label="连接时间">
                        </el-table-column>
                        
                    </el-table-column>
                </el-table>
            </el-col>
            <el-col :span="8"> 
                <el-table
                size="small "
                :data="serverData">
                <el-table-column label="主机接收消息记录" align="center">
                        <el-table-column
                            prop="number"
                            label="主机编号"
                            width="180">
                        </el-table-column>
                        <el-table-column
                            prop="ip"
                            label="接收数据">
                        </el-table-column>
                        <el-table-column
                            prop="prot"
                            width="100"
                            label="接收时间">
                        </el-table-column>
                        
                    </el-table-column>
                </el-table>
            </el-col>
            <el-col :span="8"> 
                <el-table
                size="small "
                :data="serverData.testlist">
                <el-table-column label="测试数据" align="center">
                        <el-table-column
                            prop="dataHostNumber"
                            label="主机编号"
                            width="150">
                        </el-table-column>
                        <el-table-column
                            prop="dataTerminal"
                            label="终端编号"
                            width="120">
                        </el-table-column>
                        <el-table-column
                            prop="dataReceiveCode"
                            label="接收数据"
      >
                        </el-table-column>
                        <el-table-column
                            prop="createdTime"
                            width="150"
                            label="接收时间">
                        </el-table-column>
                        
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <el-row class="serve-list" :gutter="20">
            <el-col :span="6">
                <el-table
                size="small "
                :data="serverData.heartbeat">
                <el-table-column label="心跳数据" align="center">
                        <el-table-column
                            prop="hostNumber"
                            label="主机编号"
                            width="150">
                        </el-table-column>
    
                        <el-table-column
                            prop="heartBeatCode"
                            label="接收数据"
      >
                        </el-table-column>
                        <el-table-column
                            prop="createdTime"
                            width="150"
                            label="接收时间">
                        </el-table-column>
                        
                    </el-table-column>
                </el-table>
            </el-col> 
            <el-col :span="8">
                <el-table
                size="small "
                :data="serverData.terminal">
                <el-table-column label="终端数据" align="center">
                        <el-table-column
                            prop="dataHostNumber"
                            label="主机编号"
                            width="140">
                        </el-table-column>
                        <el-table-column
                            prop="dataTerminal"
                            label="终端编号"
                            width="140">
                        </el-table-column>
                        <el-table-column
                            prop="dataReceiveCode"
                            label="接收数据"
      >
                        </el-table-column>
                        <el-table-column
                            prop="createdTime"
                            width="150"
                            label="接收时间">
                        </el-table-column>
                        
                    </el-table-column>
                </el-table>
            </el-col> 
        </el-row>
    </div>
</template>
<script>
import { getserverlist,sendmessage } from '@/api/admin';
export default {
    
    name: 'index',
    data() {
        return {
          serverData: [{
            number: 'THK154545454',
            ip: '王小虎',
            prot: '17434'
          }, {
            number: 'THK154545454',
            ip: '王小虎',
            prot: '17434'
          }, {
            number: 'THK154545454',
            ip: '王小虎',
            prot: '17434'
          }, {
            number: 'THK154545454',
            ip: '王小虎',
            prot: '17434'
          }]
        }
    },
    created() {
        this.getServerList()
        //alert('123')
        // 定时
        setInterval(() => {
            this.getServerList()
        }, 5000)
        
    },
    methods: {
        getServerList() {
            getserverlist().then(res => {
                this.serverData = res.data

            })
        },
        //查询电量
        handleDl(index,row){
            sendmessage({channelid:row.connectionId,message:"005AA5FF"}).then(res => {
                //console.log(res)
            })
        },
        // 查询信号
        handleXh(index,row){
            sendmessage({channelid:row.connectionId,message:"5343313233342341542B4353513F0D0A"}).then(res => {
                //console.log(res)
            })
        },
        // 查询卡号
        handleKh(index,row){
            sendmessage({channelid:row.connectionId,message:"5343313233342341542B49434349443F0D0A"}).then(res => {
                //console.log(res)
            })
        },
        
    }
}
</script>
<style>
 .area2{
    margin: auto;
    padding: 10px;
 }
 .serve-status,.serve-list{
    margin-top: 10px;
    font-size: 14px;
 }

</style>