<template>
    <div style="padding: 20px 40px;">
      <el-form>
        <el-form-item label="错误的号码，号码之间用英文逗号分隔：">
          <el-input v-model="source" placeholder="，，，，，，，，，，，，，，"></el-input>
        </el-form-item>
        
        <el-form-item label="点击这儿进行扫码：">
            <el-row :gutter="20">
                <el-col :span="4">
                    <el-input v-model="desctart" @keyup.enter.native="clickme"></el-input>
                </el-col>
                <el-col :span="1">
                    <el-button type="primary" @click="clickme">比对</el-button>
                </el-col>
                <el-col :span="1">
                    <el-button type="danger" @click="stop">停止</el-button>
                </el-col>
                <el-col :span="4">
                    <el-input v-model="result" readonly></el-input>
                </el-col>
            </el-row>
          
        </el-form-item>

      </el-form>
      <audio id="ddsound" src="../../assets/wav/456.wav" onended="this.loop = false;" align="center" border="0" width="1" height="1"></audio>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  
  export default {
    setup() {
      const source = ref('，，，，，，，，，，，，，，');
      const desctart = ref('');
      const result = ref('');
  
      const clickme = () => {
        const sou = source.value;
        const des = desctart.value;
        const bb = sou.split('，');
        console.log(bb);
        for (let i = 0; i < bb.length; i++) {
          if (bb[i] === des) {
            document.getElementById('ddsound').play();
            result.value = des;
            
          }
        }
        desctart.value = '';
      };
  
      const stop = () => {
        document.getElementById('ddsound').pause();
      };
  
      return {
        source,
        desctart,
        result,
        clickme,
        stop
      };
    }
  };
  </script>
  
    
<style>
 .area{
    margin: auto;
    padding: 50px;
 }
.area-btn{
    width: 50%;
    height: 80px;

}
.area-btn span{
    font-size: 25px;
}
</style>