import Vue from 'vue';
import Router from 'vue-router';
import LoginPage from '@/views/login/index.vue'; // 假设这是登录页组件
import HomePage from '@/views/index/index.vue'; // 假设这是主页组件
import Mokuai from '@/views/index/mokuai.vue'; // 假设这是主页组件
import Zhuangxiang from '@/views/index/zhuangxiang.vue'; // 假设这是主页组件
import Buhege from '@/views/index/buhege.vue'; // 假设这是主页组件
import Jianyi from '@/views/index/jianyi.vue'; // 假设这是主页组件
// 其他页面组件的导入...
import { getToken } from '@/utils/auth' // get token from cookie
Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomePage,
      meta: { requiresAuth: true } // 需要认证的路由
    },
    {
      path: '/login',
      name: 'login',
      component: LoginPage,
      meta: { requiresAuth: false } // 登录页面不需要认证
    },
    {
      path: '/mokuai',
      name: 'mokuai',
      component: Mokuai,
      meta: { requiresAuth: true } // 需要认证的路由
    },
    {
      path: '/zhuangxiang',
      name: 'zhuangxiang',
      component: Zhuangxiang,
      meta: { requiresAuth: true } // 需要认证的路由
    },
    {
      path: '/buhege',
      name: 'buhege',
      component: Buhege,
      meta: { requiresAuth: true } // 需要认证的路由
    },
    {
      path: '/jianyi',
      name: 'jianyi',
      component: Jianyi,
      meta: { requiresAuth: true } // 需要认证的路由
    },
    // 其他路由配置...
  ]
});

// 模拟的检查登录状态的函数
function isLoggedIn() {
  // 在实际应用中，这里应该检查真实的登录状态，例如从localStorage、cookie或Vuex store中获取
  return getToken() != null ? true : false; // 假设用户未登录，返回false
}

// 全局前置守卫
router.beforeEach((to, from, next) => {
  // 检查目标路由是否需要认证
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 如果需要认证，检查用户是否已经登录
    if (!isLoggedIn()) {
      // 如果用户未登录，重定向到登录页面
      next({ name: 'login' });
    } else {
      // 如果用户已登录，允许访问目标路由
      next();
    }
  } else {
    // 如果目标路由不需要认证，直接允许访问
    next();
  }
});

export default router;