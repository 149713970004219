<template>
    <div class="area">
        <el-row style="text-align: center;">
            <el-button type="primary" class="area-btn" @click="openM(1)">模块检测</el-button> <br /><br />
            <el-button type="success" class="area-btn"  @click="openM(2)">装箱前再检测</el-button><br /><br />
            <el-button type="info" class="area-btn" @click="openM(3)">不合格模块</el-button><br /><br />
            <el-button type="warning" class="area-btn" @click="openM(4)">简易数据查看</el-button><br /><br />

        </el-row>
    </div>
</template>
<script>
export default {
    name: 'index',
    methods:{
        openM(type){
            if(type==1){
                this.$router.push({name:'mokuai'})
            }else if(type==2){
                this.$router.push({name:'zhuangxiang'})
            }else if(type==3){
                this.$router.push({name:'buhege'})
            }else if(type==4){
                this.$router.push({name:'jianyi'})
            }
        }
    }
}
</script>
<style>
 .area{
    margin: auto;
    padding: 50px;
 }
.area-btn{
    width: 50%;
    height: 80px;

}
.area-btn span{
    font-size: 25px;
}
</style>