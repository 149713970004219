<template>
    <div style="padding: 20px 40px;">
        <div style="margin: auto; width: 100%; text-align: center;font-size: 50px; font-weight: 600;margin-bottom: 30px;">采集器出厂测试</div>
      <el-form>
        <el-row>
            <el-col :span="6">
                <el-form-item label="选择时间">
                <el-date-picker
                    v-model="startData"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="开始时间"
                    style="width: 180px"
                ></el-date-picker>
                <span style="margin-right: 5px; font-size: 25px">-</span>
                <el-date-picker
                    v-model="endData"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="结束时间"
                    style="width: 180px"
                ></el-date-picker>
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item label="选择终端">
                <el-input v-model="startNumber" placeholder="开始终端号" style="width: 180px"></el-input>
                <span style="margin-right: 5px; font-size: 25px">-</span>
                <el-input v-model="endNumber" placeholder="结束终端号" style="width: 180px"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item>
                    <el-button type="primary" @click="startJc">搜索</el-button>
                    <el-button type="default">重置</el-button>
                </el-form-item>
            </el-col>
        </el-row>
        
       
        
      </el-form>
      <table border="1" cellspacing="0" class="record_table">
        <thead>
          <th>序号</th>
          <th>编号测试</th>
          <th>测试1</th>
          <th>测试1时间</th>
          <th>测试2</th>
          <th>测试2时间</th>
          <th>时间差</th>
        </thead>
        <tbody v-html="tableHtml"></tbody>
      </table>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { GetTerminalInfoByJiance } from '@/api/admin';
  import axios from 'axios';
  
  export default {
  data() {
    return {
      startData: '',
      endData: '',
      startNumber: '',
      endNumber: '',
      tableData: [],
      tableHtml: ''
    }
  },
  methods: {
    startJc() {
      let _this = this;
      GetTerminalInfoByJiance({
            startdata: this.startData == null ? '' : this.startData,
            enddata: this.endData == null ? '' : this.endData,
            startnumbers: this.startNumber == null ? '' : this.startNumber,
            endnumbers: this.endNumber  == null ? '' : this.endNumber,
          })
        .then((res) => {
          //alert(JSON.stringify(res));
          const data1 = res.data1;
          const data2 = res.data2;
          const data3 = res.data3;

  
          let str = '';
          let ii = 1;

          for (let i = 0; i < data3.length; i++) {
            let qingchao = '';
            let zhongchao = '';
            str += `<tr><td>${ii}</td>`;
            //alert(data1[i].dataTerminal);
            // for (let j = 0; j < data3.length; j++) {
            //   //alert(data1[i].dataTerminal);
            //   //console.error(data1[i].dataTerminal);
            //   if (parseInt(data3[j].dataTerminal) === parseInt(data1[i].dataTerminal)) {
            str += `<td>${data3[i].dataTerminal}</td>`;
            //qingchao = data3[i].maxTime;
            //     break;
            //   }
            // }
            // if (qingchao === '') {
            //   str += '<td></td>';
            // }
            for (let j = 0; j < data1.length; j++) {
              if (parseInt(data1[j].dataTerminal) === parseInt(data3[i].dataTerminal)) {
                str += `<td>已测试1</td><td>${data1[i].maxTime}</td>`;
                qingchao = data1[i].maxTime;
                break;
              }
            }
            console.error(qingchao);
            console.error(qingchao === '');
            if (qingchao === '') {
              str += '<td>未测试1</td><td></td>';
            }
            for (let j = 0; j < data2.length; j++) {
              if (parseInt(data2[j].dataTerminal) === parseInt(data3[i].dataTerminal)) {
                str += `<td>已测试</td><td>${data2[j].maxTime}</td>`;
                zhongchao = data2[j].maxTime;
                break;
              }
            }
            
            if (zhongchao === '') {
              str += '<td>未测试</td><td></td>';
            }

            if (qingchao.length > 0 && zhongchao.length > 0) {
              const date1 = new Date(qingchao);
              const date2 = new Date(zhongchao);
              const total = (date2 - date1) / 1000;
              str += `<td>${total}</td>`;
            } else {
              str += '<td>--</td>';
            }
            str += '</tr>';
            ii++;
          }
          console.error(str);
          this.tableHtml = str;
          // 强制刷新页面
          
        });
    }
  },
  mounted() {
    // 初始化数据
  }
}
  </script>
  
  <style scoped>
  .normal {
    width: 33.3%;
    float: left;
    height: 80px;
    line-height: 80px;
    background-color: #9bc2e6;
    font-size: 40px;
    text-align: center;
    color: #fff;
  }
  .yellow {
    width: 33.3%;
    float: left;
    height: 80px;
    line-height: 80px;
    background-color: #ffc000;
  }
  .red {
    width: 33.3%;
    float: left;
    height: 80px;
    line-height: 80px;
    background-color: #ff0000;
  }
  .statusstyle {
    background-color: #990000;
    color: #fff;
    border: none;
  }
  .record_table {
    width: 100%;
    border-color: #ccc;
  }
  .record_table th {
    font-size: 20px;
    text-align: center;
    height: 45px;
  }
  .record_table tr {
    font-size: 20px;
    text-align: center;
    height: 45px;
  }
  .record_table tr:nth-child(odd) {
    background: #fff;
  }
  /* 设置偶数行颜色 */
  .record_table tr:nth-child(even) {
    background: #f1f1f1;
  }

</style>